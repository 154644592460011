export const getExploreSections = '/explore/sections'
export const getExploreStats = '/explore/stats'
export const getExploreWWIStats = '/explore/stats/wwi'
export const getExploreDistilleriesChart =
  '/explore/stats/top-most-traded-distilleries-chart'
export const getExploreSlugs = '/explore/site/slugs'

export const getExploreCategoryListingByItemTypeId = (
  categoryId = 0,
  itemTypeId = 0,
) => `/explore/category/${categoryId}/listing/${itemTypeId}`
export const getExploreCategorySuggest = (id) =>
  `/explore/category/${id}/suggest`
export const getExploreCategory = (id) => `/explore/category/${id}`
export const getListingSearchByCategoryId = (categoryId) =>
  `/explore/category/${categoryId}/search`

export const getProductDetails = (id) => `/explore/article/${id}`
export const getBasketById = (basketId) => `/explore/article/basket/${basketId}`
export const getProductDetailsStats = (id) => `/explore/article/${id}/stats`
export const getProductDetailsStockChartMarketPrice = (id) =>
  `/explore/article/${id}/stock-chart-marketprice`
export const getProductOrders = (id) => `/explore/article/${id}/orders`
export const getProductTrades = (id) => `/explore/article/${id}/trades`

export const getTraderKeyFigures = '/trader/key_figures'
export const getTraderProfile = '/trader/profile'
export const getTraderKycStatus = '/trader/kyc-status-link'
export const getTraderConsentStatus =
  '/registration/terms-and-conditions-consent'
export const getTraderOffer = '/trader/offering'
export const setTraderOffer = '/trader/offering'
export const deleteTrader = '/trader'
export const getTraderLanguage = '/trader/language'
export const putTraderLanguage = '/trader/language'

export const getPortfolioByItemId = (id) => `/trader/portfolio/${id}`
export const getPortfolioCurrent = '/trader/portfolio'
export const getPortfolioCurrentExport = '/trader/portfolio/excel-export'
export const getPortfolioClaimed = '/trader/portfolio/claimed-articles'
export const getPortfolioClaimedExport =
  '/trader/portfolio/claimed-articles/excel-export'
export const getPortfolioSold = '/trader/portfolio/sold-articles'
export const getPortfolioSoldExport =
  '/trader/portfolio/sold-articles/excel-export'
export const getPortfolioSuggest = '/trader/portfolio/suggest'
export const getPortfolioStockChart = '/trader/portfolio/stock-chart'
export const getPortfolioOnboarded = '/trader/portfolio/onboarded-articles'
export const getPortfolioItemStat = '/trader/portfolio/current-item-stat'

export const getTraderWalletCurrentBalance = '/trader/wallet/current-balance'
export const getTraderWalletBalanceTransactions =
  '/trader/wallet/balance-transactions'
export const getTraderWalletBalanceTransaction = (id) =>
  `/trader/wallet/balance-transactions/${id}`
export const traderDeposit = '/trader/wallet/deposit'
export const traderWithdraw = '/trader/wallet/withdraw'
export const getTraderDepositStatus = (id) => `/trader/wallet/deposit/${id}`
export const getTraderWalletBalanceTransactionsExport =
  '/trader/wallet/balance-transactions/excel-export'

export const getTraderTags = '/trader/tags'

export const getClaimList = 'trader/claim-list'
export const postClaimList = 'trader/claim-list'
export const deleteClaimList = 'trader/claim-list'
export const getClaimShipmentCost = 'trader/claim-list/shipment-cost'
export const executeClaimOrder = 'trader/claim-list/execute'
export const deleteClaimListItem = 'trader/claim-list/delete-item'

export const putTraderAddress = '/trader/address'
export const putTraderEmail = '/trader/email'
export const putTraderPhoneNumber = '/trader/phone'
export const putTraderPersonalDetails = '/trader/personal-details'
export const putTraderChangePassword = '/trader/password'
export const putTraderCompanyDetails = '/trader/company-details'
export const getTraderDocuments = (id) =>
  `/trader/documents?DocumentCategory=${id}`
export const traderConfirmEmail = '/trader/confirm-trader-email-change'

export const postExchangeOrderbookBuy = '/exchange/orderbook/buy/place'
export const postExchangeOrderBookBuyFillOrKill =
  '/exchange/orderbook/buy/fillOrKill'
export const getBuyOrderDefaults = '/exchange/orderbook/buy/default'
export const postExchangeOrderbookMatch = '/exchange/orderbook/match'
export const postExchangeOrderBookSellFillOrKill =
  '/exchange/orderbook/sell/fillOrKill'
export const buyBasketById = (basketId) =>
  `/exchange/orderbook/buy/basket/${basketId}`

export const calculateSellRoi = '/exchange/orderbook/calculateSellRoi'
export const getExpectedTransactionCost = (itemType = 'Bottle') =>
  `/exchange/orderbook/expectedTransactionCost?itemType=${itemType}`

export const addSellOrder = '/exchange/orderbook/sell/place'
export const getOrderbookStats = '/exchange/orderbook/stats'
export const getOrderBook = `/exchange/orderbook/orders`
export const cancelOrder = (id) => `/exchange/orderbook/${id}/cancel`

export const getSession = '/auth/session'
export const login = '/auth/login'
export const logout = '/auth/logout'
export const loginAs = '/auth/login-as'

export const createLoginCredentials = '/registration/create-login-credentials'
export const resendConfirmationEmail = '/registration/resend-confirmation-email'
export const registrationConfirmEmail = '/registration/confirm-email'
export const completeRegistration = '/registration/complete-registration'
export const tradingProfile = '/registration/trading-profile'

export const getPspRegistrationMap = '/pspRegistration/map'
